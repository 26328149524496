<template>
    <div class="app-input app-input-datepicker"
        :class="{ disabled, error, required, typing, focused, [textTransform]: textTransform, [child_class]: child_class }"
        @click="focus"
    >
        <datepicker
            :value="value"
            :monday-first="true"
            :disabled-dates="disabledDatesFunc"

            :disabled="disabled"
            :placeholder="placeholder"
            
            @focus="handleFocus"
            @blur="handleBlur"

            @input="onEnter"

            ref="field"
        ></datepicker>

        <label v-if="!error"><span>{{ label }}<i v-if="required">*</i></span></label>
        
        <div class="icon" @click="showCalendar"></div>

        <div v-if="error" class="error-message" :class="{ crop: !wrapErrorMessage }"><i></i><span>{{ error }}</span></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appInput from '@/components/app-input'

import datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import * as lang from 'vuejs-datepicker/src/locale'

export default {    
    props: {
        futureOnly: { type: Boolean, required: false, default: false },
    },

    extends: appInput,

    components: {
        datepicker,
    },

    methods: {
        focus() {
            return this
        },

        onEnter(date) {
            this.$emit('input', date)
            this.$emit('change', date)
            this.clearDebounce()
        },

        showCalendar() {
            this.$refs.field.showCalendar()
        },
    },

    computed: {
        typing: function () {
            return this.focused || this.value;
        },
        disabledDatesFunc: function () {
            if (this.futureOnly) {
                var yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return { 
                    to: yesterday,
                }
            }
            return undefined
        },
    }
}
</script>

<style lang="scss">
.app-input.app-input-datepicker {
    height: 48px;

    .icon {
        @include icon-before($icon-calendar);
        cursor: pointer;
    }

    .vdp-datepicker {
        input {
            height: 48px;

            background: transparent;
        }

        .vdp-datepicker__calendar {
            top: 115%;
            width: 300px;
            border: none;
            font-size: 16px;
            background: var(--color-component-bg-primary);
            border-radius: $border-radius-secondary;
            box-shadow: var(--box-shadow-primary);
            z-index: $z-index-dropdown;

            header {
                line-height: 42px;
                border-bottom: 1px solid var(--color-input-border);

                &:after {
                    content: "";
                    clear: both;
                    display: block;
                }

                &>:nth-child(2) {
                    font-size: 16px;
                }
            }

            .cell {
                height: 42px;
                line-height: 32px;
                border: 4px solid transparent;
                background-clip: padding-box;

                &.day-header {
                    font-size: inherit;
                    color: var(--color-btn-primary-disabled-bg);
                }

                &.selected {
                    color: var(--color-btn-primary);
                    background-color: var(--color-btn-primary-bg);
                }

                &:not(.blank):not(.disabled) {
                    &.day,
                    &.month,
                    &.year {
                        &:hover {
                            border: 4px solid transparent;
                            box-shadow: inset 0 0 0 1px var(--color-btn-primary-bg);
                        }
                    }
                }
            }
        }
    }
}
</style>